<template>
	<v-bottom-navigation dark id="footer">
		<v-btn text href='https://play.google.com/store/apps/details?id=com.jtschwartz.lynkr'>
			<img id="play-store" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style scoped>
	#footer {
		bottom: 0;
		position: absolute;
	}
	
	#play-store {
		height: inherit;
	}
</style>
