<template>
	<v-app>
		<Header/>
		
		<div class="container">
			<h1>Control your Computer from your Phone</h1>
			<br/>
			<p style="font-size: larger">Who ever said you had to be sitting at your computer to use it?</p>
			<p style="font-size: larger">Control your computer from wherever Bluetooth lets you.</p>
			<br/>
			<h2>What can you control?</h2>
			<ul>
				<li>Power down, or put your computer to sleep</li>
				<li>Lock and logout of your account</li>
				<li>Transfer your clipboard between devices (URLs will open in the default browser automatically)</li>
				<li>Change the volume or just completely mute it</li>
				<li>Play, Pause, Skip, and return to Previous tracks</li>
				<li>Customize 4 keyboard shortcuts (Count to be increased in the future)</li>
				<li><em style="font-size: smaller">More features coming soon...</em></li>
			</ul>
			<br/>
			<h2>Status of Lynkr</h2>
			<p>
				Lynkr's Android application is fully functional and deployed on the Play Store (link below), though it will have some updates soon to provide more instructions and
				increase its user-friendliness. The Desktop program is at a very different stage; although it is fully functional, it is no where near a professional program; and it
				should be noted that the Linux and Windows programs also have differing levels of functionality. As	it currently stands, it simply runs in the command line and displays
				copious amounts of unnecessary data. Given time it will run in a GUI, allow the ability to set itself to run on OS startup, and run as a minified application.
			</p>
			<br/>
			<h2>Setup</h2>
			<ol>
				<li>Download the Android app from the Play Store (link below) on your mobile device</li>
				<li>Pair your Android and Desktop devices</li>
				<li>Desktop Setup
					<ol>
						<li>Linux: Download the Linux program, untar it, follow the README, &amp; run the script</li>
						<li>Windows: Download the Windows program, unzip it, &amp; run the executable</li>
					</ol>
				</li>
				<li>Open Lynkr on your phone, and select your Windows device from the settings (Lynkr will always remember the last device you used it with)</li>
				<li>Control your computer from your Android device to your heart's content</li>
			</ol>
			<br/>
			<h2>Questions, Comments, Concerns, or Feature Requests</h2>
			<p>Please direct any of the above to <a href="mailto:support@lynkr.app">support@lynkr.app</a></p>
		</div>
		
		<Footer/>
	</v-app>
</template>

<script>
	import Header from "@/components/Header";
	import Footer from "@/components/Footer";
	
	export default {
		name:       "app",
		components: {
			Footer,
			Header
		}
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	a {
		color: #05668D !important;
	}
</style>
