<template>
	<div>
		<v-app-bar tile color="primary" hide-on-scroll>
			<v-toolbar-title><strong>Lynkr</strong></v-toolbar-title>

			<v-spacer/>

			<v-menu left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" class="font-weight-bold" color="secondary">
						<v-icon>fas fa-download</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item link href="Lynkr.tar.gz">
						<v-list-item-title>Linux</v-list-item-title>
					</v-list-item>
					<v-list-item link href="Lynkr.zip">
						<v-list-item-title>Windows</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
	</div>
</template>

<script>
	export default {
		name: "Header"
	}
</script>

<style scoped>

</style>
